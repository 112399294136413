<template>
  <main class="home">
    <div class="container">
      <div class="buttons">
        <button @click="openGame('russian-roulette')">Play «Russian Roulette»</button>
        <button @click="openLuckyPlatform">«Lucky dApps» platform</button>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    openLuckyPlatform() {
      const url = 'https://lucky-dapps.com'
      window.open(url, '_blank').focus()
    },
    openGame(game) {
      this.$router.push('/'+game)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/global/sass/home.sass'
</style>