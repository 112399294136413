import store from '@/store'

import ru from '@/translation/ru'
import en from '@/translation/en'

function t(key, params = {}) {
  let result = ''

  const lang = store?.getters['app/get__lang'] || localStorage.getItem('lang') || 'en'

  let tr = {}

  if (lang === 'ru') tr = ru
  if (lang === 'en') tr = en

  const d = key.split('.')

  for (const k of d) {
    if (!result) result = tr[k]
    else result = result[k]
  }

  if (!result) return key

  for (let param of Object.keys(params)) {
    result = result.replace('${'+`${param}`+'}', params[param])
  }

  return result
}

export default t