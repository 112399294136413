const russian_roulette = {
  namespaced: true,
  state: {
    modal: '',
    modalContent: {},
    config: {
      mode: process.env.NODE_ENV === 'development' ? 'testnet' : 'mainnet',
      feeAmount: '0.009',
      testnet: {
        dAppAddress: '3N4zabLE5JbtJfVdNL7CP1STkCnmU15Uwyz',
        serverAddress: '3PFqk3VijjErygMpj9HYd9hafXdwvXE5YsJ',
        nodeUrl: 'https://testnode2.wavesnodes.com',
        ui: 'https://wavesexplorer.com/testnet/'
      },
      mainnet: {
        dAppAddress: '3PGA23AwGA6NoSsqYVzRx34NudL6Xo4CUVw',
        serverAddress: '3NAPoUBwL7jaPW3ngVF3dT5b68q4eaHAiiC',
        nodeUrl: 'https://nodes.wavesplatform.com',
        ui: 'https://wavesexplorer.com'
      }
    },
  },
  mutations: {
    set__modal: (state, payload) => state.modal = payload,
    set__modalContent: (state, payload) => state.modalContent = payload,
    push__preLastGames: (state, payload) => state.preLastGames.push(payload),
    toggle__mode: (state) => state.config.mode = state.config.mode === 'mainnet' ? 'testnet' : 'mainnet',
  },
  getters: {
    get__modal: (state) => state.modal,
    get__modalContent: (state) => state.modalContent,
    get__config: (state) => state.config,
  },
}

export default russian_roulette