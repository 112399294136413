export default {
  waiting: 'Ожидание результата...',
  waiting_short: 'Ожидание...',
  your_bet: 'Ваша Ставка',
  state: {
    default: 'Заряди Револьвер',
    waiting: 'Вращаем барабан...',
    win: 'ВЫ ПОБЕДИЛИ!',
    lost: 'ВЫ ПРОИГРАЛИ :(',
  },
  switch_mainnet: 'Переведите WavesKeeper в режим MainNet',
  keeper_title: 'Русская Рулетка',
  keeper_desc: 'Блокчейн Игра',
  step_1: 'Зарядите барабан патронами.',
  step_2: 'Сделайте свою ставку.',
  step_3: 'После обучения, для начала игры, пожалуйста, нажмите сюда.',
  step_4: 'При необходимости прочтите правила игры.',
  skip: 'Пропустить',
  bet: 'Ставка',
  game_result: 'Результат Игры',
  game_details: 'Детали игры и Смарт Контракт',
  good_luck: 'Удачи!',
  rules_title: 'Как играть в Russian Roulette',
  th_player: 'Игрок',
  th_player_bet: 'Сумма',
  th_player_choice: 'Результат игры',
  th_system_choice: 'Выбор Системы',
  th_won: 'Выиграл',
  won: 'Выиграл',
  play: 'Играть',
  install_keeper: 'Вам нужен кошелек WAVES, чтобы начать игру',
  Winning_Chance: 'Победный шанс',
  Winning_Coefficient: 'Коэффициент',
  You_May_Win: 'Можно выиграть',
  loading: 'Загрузка...',
  about: 'Описание',
  you_need: 'Чтобы играть вам нужно',
  game_instructions: 'Инструкция к игре',
  tutorial: 'Обучение',
  rules: 'Правила',
  telegram: 'Телеграм',
  smart_contract: 'Смарт Контракт',
  show_player: 'Показать Игрока',
  install: 'Установить',
  server_offline: 'Сервер оффлайн',
  switch_to: 'Пожалуйста, переключите WavesKeeper на',
  rules_1: 'Игра полностью децентрализована: не существует источников внешнего влияния и процесс игры контролируется исключительно по правилам смарт-контракта.',
  rules_2: 'Вы делаете ставку: чем выше ставка, тем больше вы выиграете. Затем вы заряжаете барабан револьвера и пытаетесь предугадать какой патрон окажется смертельным. Обратите внимание что вы можете зарядить от 1 до 5 патронов в барабан револьвера. Чем меньше патронов вы зарядите, тем больше шанс на победу, но тем меньше Waves мы сможете выиграть.',
  rules_3: 'Игра полностью прозрачная и имеет открытый код, можете посмотреть его на dApp аккаунте игры.',
  rules_4: 'Последния версия одного из двух браузеров: Google Chrome or Mozilla Firefox',
  rules_5: 'Установленный Waves Keeper в режиме Mainnet',
  rules_6: 'Как миннимум 1.05 WAVES (1 WAVES это миннимальная ставка и 0.05 WAVES это коммисия блокчейна)',
  rules_7: 'Зарядите (1-5) патронов в барабан чтобы сделать ставку.',
  rules_8: 'Сделайте свою ставку: 1, 2, 4, 8 или 14 WAVES.',
  rules_9: 'Нажмите Играть.',
  rules_10: 'Подтвердите детали вашей ставки с помощью Waves Keeper:',
  rules_11: '1/2/4/8/14 WAVES + 0.005 WAVES коммисия блокчейна.',
  rules_12: 'Через несколько секунд вы узнаете результат игры: выиграли вы или проиграли. В случае чего, вы всегда можете проверить Смарт Контракт!',
  rules_13: 'В случае чего, вы всегда можете проверить Смарт Контракт!',
  rules_14: 'Все WAVES которые вы выиграете будут отправлены на ваш аккаунт в течении 1 блока (~1 минута)',
  change_mode: 'Переключить в',
  how_to_play: 'Как играть?',
  project_news: 'Новости проекта',
  coming_soon: 'Скоро запуск',
  translate_to_en: 'Перевести на EN',
  translate_to_ru: 'Перевести на RU',
  mute: 'Выключить звук',
  unmute: 'Включить звук',
  history_loading: 'Загрузка истории...'
}