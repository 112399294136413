export default {
  waiting: 'Waiting for result...',
  waiting_short: 'Waiting...',
  your_bet: 'Your Bet',
  state: {
    default: 'Charge Revolver',
    waiting: 'Spinning...',
    win: 'YOU WON!',
    lost: 'YOU LOOSE :(',
  },
  switch_mainnet: 'Please Switch WavesKeeper to MainNet',
  keeper_title: 'Russian Roulette',
  keeper_desc: 'Blockchain Game',
  step_1: 'Put some bullets here.',
  step_2: 'Make your bet.',
  step_3: 'After completing the tutorial, please use this button to start the game.',
  step_4: 'Read the game rules and instructions.',
  skip: 'Skip Tutorial',
  bet: 'Bet',
  game_result: 'Game Result',
  game_details: 'Game Details and SmartContract',
  good_luck: 'Good luck!',
  rules_title: 'How to play Russian Roulette',
  th_player: 'Player',
  th_player_bet: 'Player Bet',
  th_player_choice: 'Game Result',
  th_system_choice: 'System Choice',
  th_won: 'Won',
  won: 'Won',
  play: 'Play',
  install_keeper: 'You need a WAVES wallet to play the game',
  Winning_Chance: 'Winning Chance',
  Winning_Coefficient: 'Winning Coefficient',
  You_May_Win: 'You May Win',
  loading: 'Loading...',
  about: 'About',
  you_need: 'To play you need',
  game_instructions: 'Game instructions',
  tutorial: 'Tutorial',
  rules: 'Rules',
  telegram: 'Telegram',
  smart_contract: 'Smart Contract',
  show_player: 'Show Player',
  install: 'Install',
  server_offline: 'Server Offline',
  switch_to: 'Please, set WavesKeeper to',
  rules_1: 'The game is completely decentralized: there are no sources of external influence and it is controlled solely by the rules of the smart-contract.',
  rules_2: 'You make a bet: the higher your bet is, the more you can win. Then you are trying to guess the winning bullets order. Note that you are able to put 1-5 bullets in the revolver drum at the same time. The less bullets selected, the greater your chances to win, but the less your winning coefficient will be.',
  rules_3: 'The game is trustless, fair, and transparent.',
  rules_4: 'The latest version of the web browser: Google Chrome or Mozilla Firefox (only);',
  rules_5: 'Waves Keeper installed;',
  rules_6: 'At least 1.05 WAVES (1 WAVES is a minimum bet and 0.05 WAVES Invocation transaction commission).',
  rules_7: 'Put (up to 5) bullets to the gun to bet on.',
  rules_8: 'Make your bet: 1, 2, 4, 8 or 14 WAVES.',
  rules_9: 'Click Play.',
  rules_10: 'Confirm your bet and the choice of the bullets with Waves Keeper:',
  rules_11: '1/2/4/8/14 WAVES + 0.005 WAVES Invocation transaction commission.',
  rules_12: `In a moment find out the result: win or lose.`,
  rules_13: `Don't forget to check out the smart contract!`,
  rules_14: 'All the WAVES that you won will be sent from the smart contract to your account in 1 block (~1 minute)',
  change_mode: 'Change to',
  how_to_play: 'How to play',
  project_news: 'Project news',
  coming_soon: 'Coming soon',
  translate_to_en: 'Translate to EN',
  translate_to_ru: 'Translate to RU',
  mute: 'Mute',
  unmute: 'Unmute',
  history_loading: 'History loading...'
}