import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from '@/store/app'
import russian_roulette from '@/store/russian_roulette'

export default new Vuex.Store({
  modules: { app, russian_roulette }
})