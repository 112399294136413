<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  computed: {
    socket() {
      return this.$store.getters['app/get__socket']
    }
  },
  mounted() {
    this.socket.on("disconnect", () => {
      let message = 'Connection to server lost'
      console.log('%c '+message, 'background: #fa305c; color: #ffffff; border-radius: 4px; padding: 2px 4px 2px')
      this.$store.commit('app/set__online', false)
    })

    this.socket.onAny((type, payload) => {
      console.log({ type, payload })

      if (type === 'user/connect') {
        console.log('%c Connection to server established', 'background: #4ac481; color: #ffffff; border-radius: 4px; padding: 2px 4px 2px')
        this.$store.commit('app/set__online', true)
      }
    })

    if (localStorage.getItem('lang')) return
    let language = window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : 'en'
    language = language.substr(0, 2).toLowerCase()
    this.$store.commit('app/set__lang', language)
  }
}
</script>

<style lang="sass">
@import '@/assets/global/sass/style.sass'
</style>