import io from 'socket.io-client'

const socket = io(process.env.NODE_ENV === 'development' ? 'http://localhost:1111' : 'https://waves-server.lucky-dapps.com')

const app = {
  namespaced: true,
  state: {
    mute: false,
    lang: 'ru',
    user: {},
    online: null,
    data: {
      WavesKeeper: null,
      wavesKeeperLink: 'https://chrome.google.com/webstore/detail/waves-keeper/lpilbniiabackdjcionkobglmddfbcjo',
      tgLink: 'https://t.me/waves_games',
    },
    socket
  },
  actions: {
    emit(_, [type, payload]) {
      socket.emit(type, payload)
    }
  },
  mutations: {
    toggle__mute: (state) => state.mute = !state.mute,
    set__lang: (state, payload) => {
      state.lang = payload
      localStorage.setItem('lang', payload)
    },
    set__user: (state, payload) => {
      state.user = payload
    },
    set__online: (state, payload) => {
      state.online = payload
    },
    set__waves_keeper: (state, payload) => {
      state.data.WavesKeeper = payload
    }
  },
  getters: {
    get__lang: (state) => state.lang,
    get__mute: (state) => state.mute,
    get__user: (state) => state.user,
    get__socket: (state) => state.socket,
    get__online: (state) => state.online,
    get__data: (state) => state.data,
  }
}

export default app