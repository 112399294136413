import Vue from 'vue'
import App from '@/App'
import router from '@/router.js'
import store from '@/store.js'

import t from '@/logic/translation'

Vue.config.productionTip = false

import VueTippy from 'vue-tippy'

Vue.use(VueTippy, {
  theme: 'light',
  arrow : true,
  arrowType : 'round'
})

Vue.mixin({
  methods: {
    t,
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')